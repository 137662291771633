<!--
 * @Author: Dyf
 * @LastEditors: wang hai yang
 * @Date: 2023-03-13 09:33:34
 * @LastEditTime: 2023-05-18 18:52:45
 * @Descripttion: 【系统】账号管理
-->
<style lang="scss" scoped>
.account {
    @include innerPage(56px 34px 48px 15px);
    @include pageTitle(0);
    @include defalutTable(calc(100% - 38px), 20px);
    @include pageFoot;
}

.dialog-container {
    box-sizing: border-box;
    padding: 34px 20px 48px 18px;

    .el-form-item:last-child {
        margin-bottom: 0;
    }

    .danger {
        color: #ff4669;
        margin-bottom: 8px;
        margin-top: -8px;

        ::v-deep .el-form-item__label {
            color: #ff4669;
        }

        p {
            box-sizing: border-box;
            padding-left: 26px;
        }
    }
}
</style>

<template>
    <section class="account">
        <div class="page-title">
            <h3>账号管理</h3>
            <el-button class="light" type="custom_primary" size="medium" @click="accountDialog = true">
                新增+
            </el-button>
        </div>
        <div class="table-box">
            <div class="table-inner" ref="tableInner">
                <el-table :data="tableData" height="100%">
                    <el-table-column align="center" prop="scuse_name" label="账号名称" min-width="18.03%" />
                    <el-table-column align="center" prop="scuse_account" label="账号" min-width="21.47%" />
                    <el-table-column align="center" label="所属角色" min-width="19.67%">
                        <template slot-scope="scope">
                            {{ scope.row.scuse_issuper == 10 && '超级管理员' || scope.row.arole_name || '暂未分配' }}
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="状态" min-width="10.7%">
                        <template slot-scope="scope">
                            <p class="table-status"
                                :class="{ success: scope.row.scuse_status == 10, failed: scope.row.scuse_status == 20 }">
                                {{ scope.row.scuse_status == 10 && '启用' || '禁用' }}
                            </p>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="操作" min-width="30.13%">
                        <template slot-scope="scope">
                            <el-button type="custom_primary" size="mini" plain :disabled="scope.row.scuse_issuper == 10"
                                @click="accountEdit(scope.row)">
                                编辑
                            </el-button>
                            <el-button type="custom_danger" size="mini" plain :disabled="scope.row.scuse_issuper == 10"
                                @click="removeAccount(scope.row)">
                                删除
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="table-foot custom-foot">
                <customPagination :current='searchForm.pageindex' :total="total" @pageChange="flippingPage" />
            </div>
        </div>
        <el-dialog :title="accountForm.scuse_id && '编辑账号' || '新增账号'" width="558px" :visible.sync="accountDialog"
            :show-close="false" @close="hideDialog">
            <div class="dialog-container">
                <el-form label-width="70px" ref="accountForm" :rules="accountRules" :model="accountForm">
                    <el-form-item label="账号名称" prop="scuse_name">
                        <el-input v-model.trim="accountForm.scuse_name" maxlength="100" placeholder="请输入名称" />
                    </el-form-item>
                    <el-form-item label="账号" prop="scuse_account">
                        <el-input v-model.trim="accountForm.scuse_account" maxlength="100" placeholder="请输入账号" />
                    </el-form-item>
                    <el-form-item label="密码">
                        <el-input v-model.trim="accountForm.scuse_password" type="password" maxlength="16"
                            :placeholder="accountForm.scuse_id ? '不更改则不输入(8~16位)' : '不输入则默认密码(8~16位)'" />
                    </el-form-item>
                    <el-form-item label="所属角色" prop="rolearr">
                        <el-select multiple v-model="accountForm.rolearr" placeholder="请选择角色">
                            <el-option v-for="item in roleList" :key="item.key" :label="item.value"
                                :value="item.key"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="默认密码" class="danger">
                        <p>{{ defaultPassword }}</p>
                    </el-form-item>
                    <el-form-item label="状态">
                        <el-switch v-model="accountForm.scuse_status" :active-value="10" :inactive-value="20"
                            :active-text="accountForm.scuse_status == 10 && '启用' || '禁用'" />
                    </el-form-item>
                </el-form>
            </div>
            <div slot="footer">
                <el-button type="custom_info" size="small" @click="hideDialog">
                    取 消
                </el-button>
                <el-button type="custom_primary" size="small" @click="handleConfirm" :disabled="btnload"
                    v-loading="btnload">
                    确 定
                </el-button>
            </div>
        </el-dialog>
    </section>
</template>

<script>
import { accountList, accountAdd, accountInfo, accountEdit, accountRemove, roleselect } from "@api/system";
import customPagination from "@comp/customPagination";
export default {
    name: 'system_account',
    components: {
        customPagination
    },
    data() {
        return {
            searchForm: {
                pageindex: 1,
                pagesize: 10,
            },
            total: 0,
            type: 'add',
            accountRules: {
                scuse_name: [{ required: true, message: "请输入账号名称", trigger: "blur" }],
                scuse_account: [{ required: true, message: "请输入账号", trigger: "blur" }],
                rolearr: [{ type: "array", required: true, message: "请选择角色", trigger: "change" }],
            },
            tableData: [],
            accountDialog: false,
            accountForm: { scuse_status: 10 },
            btnload: false,
            roleList: [],
            defaultPassword: ""
        };
    },
    created() {
        this.init();
        this.getRoleList();
    },
    methods: {
        /** 切换页码 */
        flippingPage(val) {
            this.searchForm.pageindex = val;
            this.init();
        },
        async getRoleList() {
            const res = await roleselect({ key: "arole_id", value: "arole_name" });
            if (res.status == 200) {
                this.roleList = res.data;
            }
        },
        /**初始化 */
        async init() {
            const res = await accountList(this.searchForm);
            this.tableData = res.data.data;
            this.total = res.data.allcount;
            this.defaultPassword = res.data.defaultPassword;
        },
        /** 删除账号 */
        removeAccount(row) {
            this.$msgbox({
                title: '删除',
                message: '确认删除当前账号？',
                type: 'warning',
                showClose: false,
                showCancelButton: true,
                cancelButtonClass: 'el-button--custom_info',
                confirmButtonClass: 'el-button--custom_primary',
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            }).then(async () => {
                const res = await accountRemove({ id: row.scuse_id });
                if (res.status == 200) {
                    this.$message({
                        type: 'success',
                        duration: 1500,
                        message: '账号已删除！'
                    });
                    this.total = this.total - 1 < 0 && 0 || this.total - 1;
                    if (this.tableData.length == 1 && this.searchForm.pageindex != 1) {
                        this.searchForm.pageindex = this.searchForm.pageindex - 1;
                    }

                    this.init();
                }
            }).catch(() => {
                this.$message({
                    type: 'info',
                    duration: 1500,
                    message: '操作已取消'
                })
            });
        },
        /** 编辑学生 */
        async accountEdit(row) {
            const res = await accountInfo({ id: row.scuse_id });
            if (res.status == 200) {
                this.accountForm = res.data;
                delete this.accountForm.scuse_password;
                this.accountDialog = true;
            }
        },
        /** 确认添加 */
        async handleConfirm() {
            this.$refs.accountForm.validate(async (valid) => {
                if (valid) {
                    this.btnload = true;
                    let params = {
                        scuse_name: this.accountForm.scuse_name,
                        scuse_account: this.accountForm.scuse_account,
                        scuse_password: this.accountForm.scuse_password,
                        scuse_status: this.accountForm.scuse_status,
                        rolearr: this.accountForm.rolearr,
                    };
                    let res = {};
                    if (this.accountForm.scuse_id) {
                        params.scuse_id = this.accountForm.scuse_id;
                        res = await accountEdit(params);
                    } else {
                        res = await accountAdd(params);
                    }
                    this.btnload = false;
                    if (res.status == 200) {
                        this.$message.success(params.scuse_id && "编辑成功" || "添加成功");
                        this.accountDialog = false;
                        this.init();
                    }
                }
            });
        },
        /** 关闭弹窗 */
        hideDialog() {
            this.accountDialog = false;
            this.$refs.accountForm.resetFields();
            this.accountForm = { scuse_status: 10 };
        },
    },
}
</script>